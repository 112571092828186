import { Container, Grid, Paper, Typography } from "@mui/material";
import copy from "copy-to-clipboard";
import React from "react";

const colors = ['#fefcfe',
'#fafbfc',
'#fbfefc',
'#fdfffb',
'#fafffc',
'#fdfafb',
'#fcfbfb',
'#fefafd',
'#fdfcfe',
'#fbfffc',
'#fafcfd',
'#fafffa',
'#fafafc',
'#fbfcfe',
'#fbfafd',
'#fafcfd',
'#fffcff',
'#fdfefa',
'#fffefe',
'#fcfafb',
'#fafffc',
'#fafbfc',
'#fafbfa',
'#fffbfc',
'#fbfcfa',
'#fcfefa',
'#fcffff',
'#fdfefd',
'#fdfdfa',
'#fffdfe',
'#fafcfa',
'#fefefe',
'#fcfcff',
'#fafcfe',
'#fdfbfa',
'#fdfaff',
'#fcfcfe',
'#fbfefe',
'#fefefd',
'#fffffc',
'#fdffff',
'#fffdfa',
'#fffeff',
'#fcfffd',
'#fdfbfb',
'#fbfafa',
'#fcfffb',
'#fcfdfc',
'#fffcfb',
'#fdfcfb',
'#fafbfa',
'#fffffa',
'#fffefc',
'#fcfdfe',
'#fffafa',
'#fffbff',
'#fafffd',
'#ffffff',
'#fefefe',
'#fefbff',
'#fcfefb',
'#fafbfd',
'#fcfbfc',
'#fbfffb',
'#fafcfc',
'#fdfefc',
'#fafdfd',
'#fdfaff',
'#fefbfe',
'#fcfcfb',
'#fafefb',
'#fafafc',
'#fbffff',
'#fffdfa',
'#fcfefe',
'#fefcfa',
'#fefefb',
'#fafdfe',
'#fafffc',
'#fafefd',
'#fcfbfb',
'#fbfdff',
'#fcffff',
'#fffcfa',
'#fefbfd',
'#fcfeff',
'#fcfffd',
'#fdfefc',
'#fafdfe',
'#fffafe',
'#fffefa',
'#fefffb',
'#fbfbfa',
'#fcfafe',
'#fbfdfa',
'#fdfbfb',
'#fafafe',
'#fffaff',
'#feffff',
'#fefbff',
'#fbfcfe',
'#fcfefd',
'#fcfbfb',
'#fbfffd',
'#faffff',
'#fefdfd',
'#fafafe',
'#fdfdfd',
'#fafdfc',
'#fbfbfc',
'#fcfbfe',
'#fdfafc',
'#fafaff',
'#fafefb',
'#fffbff',
'#fbfdfc',
'#fdfdfd',
'#fafdfe',
'#fefbfe',
'#fefefa',
'#fefcfd',
'#fdfefb',
'#fbfefa',
'#fdfbfc',
'#fefaff',
'#fbfffa',
'#fefdfb',
'#fefefe',
'#fdfafd',
'#fcfdfc',
'#fbfcfc',
'#fcfdfa',
'#fffcff',
'#fdfbff',
'#fafcfc',
'#fafffa',
'#fdfeff',
'#fafafe',
'#fafbfa',
'#fdfafa',
'#fefbfb',
'#fbfffb',
'#fefefb',
'#fffefa',
'#fbfffc',
'#fafcfc',
'#fafffe',
'#fffbfd',
'#fcfdff',
'#fffafc',
'#fafefb',
'#ffffff',
'#fbfdff',
'#fffbfd',
'#fcfafc',
'#fcfffe',
'#fbfafb',
'#fbfefd',
'#fafafe',
'#fffafa',
'#fafdfc',
'#fbfffd',
'#fcfffa',
'#fbfdff',
'#fefafb',
'#fafdfd',
'#fefffb',
'#fefefb',
'#fbfcfe',
'#fdfbfa',
'#fefefa',
'#fbfdfa',
'#fbfcfb',
'#fafcfb',
'#fdfafa',
'#fdfdfe',
'#fcfbff',
'#fafdff',
'#fafffa',
'#fffafa',
'#fdfefe',
'#fdfcff',
'#fbfafc',
'#fafefe',
'#fefafb',
'#fdfffc',
'#fefafb',
'#fbfaff',
'#fdfdfc',
'#fafffc',
'#fdfefd',
'#fffaff',
'#fdfdfb',
'#fafafa',
'#fafbff',
'#fbffff',
'#fefbfb',
'#fffdfc',
'#fefcfb',
'#fdfbfa']

export const Palletes = () => {
    return (
        <Container maxWidth="xl">
            <Grid container spacing={2} >
            {
                colors.map(color => (<Grid item lg={2}>
                    <Paper onClick={() => {copy(color)}} key={color} sx={{cursor:"pointer",width:'100%', height:"200px"}} variant="outlined">
                        <div style={{backgroundColor:color, width:"100%", height:"100%", position:"relative"}}>
                            <Typography sx={{top:8,left:4, position:"absolute"}} variant="button">{color}</Typography>
                        </div>
                    </Paper>
                </Grid>))
            }
        </Grid>
        </Container>
    )
}
