import React, { useEffect, useState } from "react";
import { INFT } from "../../../shared/interfaces/nft";
import {
  Box,
  Button,
  Container,
  Paper,
  Skeleton,
  Typography,useTheme
} from "@mui/material";
import { getRandomString } from "../../../shared/utils/array-utils";
import { bgColors } from "../../../shared/data/colors";
import { Link } from "react-router-dom";
interface Props {
  nft: INFT;
}
export const NFTCard: React.FC<Props> = ({ nft }) => {
  const [isImageLoading, setImageLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState(nft.image.originalUrl);
  const theme = useTheme();
  useEffect(() => {
    if (nft && nft.image) {
      let img = nft.image.cachedUrl
        ? nft.image.cachedUrl
        : nft.image.originalUrl;
      setImageUrl(img);
    }
  }, [nft]);
  return (
    <Box height={"350px"}>
      <Paper
        sx={{
          borderRadius: "0.8rem",
          backgroundColor: getRandomString(bgColors),
        }}
        className="custom-paper"
      >
        {nft && nft.image && (
          <img
            width={"100%"}
            height="250px"
            src={imageUrl}
            style={{
              display: isImageLoading ? "none" : "block",
              borderTopLeftRadius: "0.8rem",
              borderTopRightRadius: "0.8rem",
              backgroundColor: "#fff"
            }}
            onLoad={() => setImageLoading(false)}
            alt="nft-artwork-thumbnail"
          />
        )}
        {isImageLoading && (
          <Skeleton
            sx={{
              borderTopLeftRadius: "0.8rem",
              borderTopRightRadius: "0.8rem",
            }}
            variant="rectangular"
            width="100%"
            height="250px"
          />
        )}
        <Container>
          <Box sx={{ mt: 1.5 }}>
            <Link style={{textDecoration:"none", color:theme.palette.text.primary}} to={`/nft/${nft.contract.address}/${nft.tokenId}`}>
            <Typography sx={{fontSize:"1rem", fontWeight:500}} className="truncate">{nft.name}</Typography>
            </Link>
          </Box>
        </Container>
      </Paper>
    </Box>
  );
};
