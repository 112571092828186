import { ICollection } from "./../../../shared/interfaces/data-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { setAlertStatus } from "../../slices/app-slice";
import { AppDispatch } from "../../store";
const apiUrl = process.env.REACT_APP_API_URL;
export const noImgCid = "bafkreihyaeahugm4esjtqrq4227kariwvz3l5w6kyj6dxejjtybmbb2c74";
export const getCollectionsByPage = createAsyncThunk<
  any,
  number,
  { dispatch: AppDispatch }
>("collections/getAllbyPage", async (page, thunkAPI) => {
  try {
    // Call the API function with parameters
    const data = await fetchDataFromApi(page);
    return {
      page: page,
      data: data,
    };
  } catch (error) {
    // Handle errors here
    thunkAPI.dispatch(
      setAlertStatus({
        isOpen: true,
        severity: "error",
        message: "Error retreiving NFT collections!",
      })
    );
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

const fetchDataFromApi = async (page) => {
  const response = await axios.get<any, AxiosResponse<ICollection[]>, any>(
    `${apiUrl}collections/?page=${page}&limit=48`
  );
  return response.data;
};
