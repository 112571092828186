import { IHomeBanners } from "./../../../shared/interfaces/data-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { setAlertStatus } from "../../slices/app-slice";
import { AppDispatch } from "../../store";
const apiUrl = process.env.REACT_APP_API_URL;
export const noImgCid = "bafkreihyaeahugm4esjtqrq4227kariwvz3l5w6kyj6dxejjtybmbb2c74";
export const getHomeBanners = createAsyncThunk<
  any,
  void,
  { dispatch: AppDispatch }
>("app/getHomeBanners", async (params, thunkAPI) => {
  try {
    // Call the API function with parameters
    const data = await fetchDataFromApi();
    // Return the data to be stored in the Redux store
    return data;
  } catch (error) {
    // Handle errors here
    thunkAPI.dispatch(
      setAlertStatus({
        isOpen: true,
        severity: "error",
        message: "Error retreiving Home Banners!",
      })
    );
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

const fetchDataFromApi = async () => {
  const response = await axios.get<any, AxiosResponse<IHomeBanners[]>, any>(
    `${apiUrl}banners/home`
  );
  return response.data.map((x) => {
    return { ...x, ipfsCID: `https://ipfs.io/ipfs/${x.ipfsCID}` };
  });
};