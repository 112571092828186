import {
  ICollection,
  TopMintingCollections,
} from "./../../shared/interfaces/data-interfaces";
import { createSlice } from "@reduxjs/toolkit";
import { getCollectionsByPage } from "../thunks/collections/get-collection";
import { getTopMintingCollections } from "../thunks/collections/top-minting-collections";
import { getOpenSeaCollectionMetadata } from "../thunks/collections/get-total-supply";
import { getSelectedCollection } from "../thunks/collections/get-selected-collection";
import { INFT } from "../../shared/interfaces/nft";
import { getCollectionNFTs } from "../thunks/collections/getNFTs";

interface State {
  collections: ICollection[];
  topMinting: TopMintingCollections[];
  loadingMinting: boolean;
  selectedCollection: ICollection | undefined;
  selectedCollectionNfts: {
    next: string | undefined;
    data: INFT[];
    hasMore:boolean,
  };
  loading: boolean;
  hasMore: boolean;
  page: number;
}
const initialState: State = {
  collections: [],
  topMinting: [],
  loadingMinting: true,
  hasMore: false,
  selectedCollection: undefined,
  selectedCollectionNfts: { data: [], next: undefined , hasMore: false},
  loading: false,
  page: 0,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCollectionLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedCollection: (state, action) => {
      state.selectedCollection = state.collections.find(
        (x) => x.address === action.payload
      );
    },
    destroyCollections: (state) => {
      state.selectedCollectionNfts = { data: [], next: undefined, hasMore: false };
      state.selectedCollection = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCollectionsByPage.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.length < 48) {
        state.hasMore = false;
      } else {
        state.hasMore = true;
      }
      state.page = action.payload.page;
      state.collections = [...state.collections, ...action.payload.data];
    });
    builder.addCase(getCollectionsByPage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollectionsByPage.rejected, (state, action) => {
      state.loading = false;
      state.collections = [];
    });
    builder.addCase(getTopMintingCollections.fulfilled, (state, action) => {
      state.loadingMinting = false;
      state.topMinting = action.payload;
    });
    builder.addCase(getTopMintingCollections.rejected, (state) => {
      state.loadingMinting = false;
    });
    builder.addCase(getTopMintingCollections.pending, (state, action) => {
      state.loadingMinting = true;
    });
    builder.addCase(getOpenSeaCollectionMetadata.fulfilled, (state, action) => {
      state.collections = updateOpenSeaMetadata(
        state.collections,
        action.payload.address,
        action.payload.data
      );
    });
    builder.addCase(getSelectedCollection.fulfilled, (state, action) => {
      let temp = { ...action.payload };
      temp = {
        ...temp,
        totalSupply: action.payload.openSeaMetadata!.total_supply,
      };

      state.selectedCollection = temp;
    });
    builder.addCase(getCollectionNFTs.fulfilled, (state, action) => {
      state.selectedCollectionNfts.data = [...state.selectedCollectionNfts.data, ...action.payload.nfts];
      state.selectedCollectionNfts.next = action.payload.pageKey;
      if(action.payload.pageKey){
        state.selectedCollectionNfts.hasMore = true;
      }else{
        state.selectedCollectionNfts.hasMore = false;
      }
    });
  },
});

function updateOpenSeaMetadata(
  data: ICollection[],
  addressToUpdate: string,
  newMetadata: any
): ICollection[] {
  const collections = data.slice();
  return collections.map((collection) => {
    if (collection.address === addressToUpdate) {
      return {
        ...collection,
        openSeaMetadata: newMetadata,
        totalSupply: newMetadata.total_supply,
      };
    }
    return collection;
  });
}
export const { setCollectionLoading, setSelectedCollection, destroyCollections } = appSlice.actions;
export default appSlice.reducer;
