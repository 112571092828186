import React from "react";
import { ICollection } from "../../../shared/interfaces/data-interfaces";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { getNetworkLogo } from "../../../shared/data/chains";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
interface Props {
  collection?: ICollection;
}

export const CollectionMeta: React.FC<Props> = ({ collection }: Props) => {
  const [Logo, setLogo] = useState<any>(undefined);
  useEffect(() => {
    setLogo(getNetworkLogo(8453));
    console.log(getNetworkLogo(8453));
  }, []);
  return (
    <Box>
      <Box sx={{ width: "100%", display: "flex", mt: 1, alignItems: "center" }}>
        <span className="spacer" />
        {Logo && (
          <Box
            sx={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() =>
              window.open(
                `https://basescan.org/address/${collection?.address}`,
                "__blank"
              )
            }
          >
            <Logo />
          </Box>
        )}
        <IconButton>
          <MoreHorizIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ p: 3, pb: 2 }}>
        {collection && (
          <>
            <Typography
              variant="h5"
              sx={{ fontWeight: 500, fontSize: "1.5rem", lineHeight: "2rem" }}
            >
              {collection.name}
            </Typography>
            <Typography sx={{ mt: 1 }}>
              Creator earnings{" "}
              {collection.openSeaMetadata.fees[1]
                ? collection.openSeaMetadata.fees[1].fee
                : "0"}
              %
            </Typography>
          </>
        )}
        {!collection && (
          <>
            <Skeleton
              variant="text"
              sx={{
                fontWeight: 500,
                fontSize: "1.5rem",
                lineHeight: "2rem",
                width: "300px",
              }}
            />
            <Skeleton variant="text" sx={{ mt: 1, width: "300px" }} />
          </>
        )}
      </Box>
      <Box
        sx={{
          pl: 3,
          pb:3,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          width: "50%",
        }}
      >
        <Box
          sx={{ alignItems: "center", width: "100%", justifyContent: "start" }}
        >
          {collection && (
            <>
              <Typography sx={{ fontWeight: 500, fontSize: "1.2rem" }}>
                0.00 ETH
              </Typography>
              <Typography color="GrayText">total volume</Typography>
            </>
          )}
          {!collection && (
            <>
              <Skeleton variant="text" sx={{ width:"100px" }}/ >
                
              <Skeleton variant="text" sx={{ width:"100px" }}/ >
            </>
          )}
        </Box>
        <Box
          sx={{ alignItems: "center", width: "100%", justifyContent: "center" }}
        >
          {collection && (
            <>
              <Typography sx={{ fontWeight: 500, fontSize: "1.2rem" }}>
                0.00 ETH
              </Typography>
              <Typography color="GrayText">floor price</Typography>
            </>
          )}
          {!collection && (
            <>
              <Skeleton variant="text" sx={{ width:"100px" }}/ >
                
              <Skeleton variant="text" sx={{ width:"100px" }}/ >
            </>
          )}
        </Box>
        <Box
          sx={{ alignItems: "center", width: "100%", justifyContent: "center" }}
        >
          {collection && (
            <>
              <Typography sx={{ fontWeight: 500, fontSize: "1.2rem" }}>
                {collection?.totalSupply ? collection.totalSupply : "0"}
              </Typography>
              <Typography color="GrayText">total items</Typography>
            </>
          )}
          {!collection && (
            <>
              <Skeleton variant="text" sx={{ width:"100px" }}/ >
                
              <Skeleton variant="text" sx={{ width:"100px" }}/ >
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
