/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, ReactElement, useEffect } from "react";
import { HomeBanners } from "./components/home-banners";
import { Palletes } from "./components/palletes";


export const HomePage = (): ReactElement => {
  
  useEffect(() => {
    
  }, []);
  return (
    <Fragment>
      <HomeBanners />
      <Palletes />
    </Fragment>
  );
};
