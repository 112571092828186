import { configureStore } from '@reduxjs/toolkit';
import appReducer from "./slices/app-slice";
import collectionReducer from "./slices/collections-slice";
import { useDispatch } from 'react-redux';

export const store = configureStore({
  reducer: {
    app: appReducer,
    collections: collectionReducer
  },
})
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch 
export type RootState = ReturnType<typeof store.getState>