import { Box, Button, TextField } from '@mui/material'
import React from 'react'

export const PriceFilter = () => {
    return (
        <Box sx={{mt:1,display:"flex", alignItems:"center", flexDirection:"column"}}>
            <Box display="flex" width="100%">
                <TextField sx={{mr:1}} placeholder='Min' label="Min" autoComplete='off' />
                <TextField sx={{ml:1}} placeholder='Max' label="Max" autoComplete='off'/>
            </Box>
            <Button sx={{mt:2, borderRadius:"20px"}} variant='contained' fullWidth color='primary'>Apply</Button>
        </Box>
    )
}