import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setAlertStatus } from "../../slices/app-slice";
import { AppDispatch } from "../../store";

const apiUrl = process.env.REACT_APP_API_URL;

export const getOpenSeaCollectionMetadata = createAsyncThunk<
  {data:any, address: string},
  string,
  { dispatch: AppDispatch }
>("collections/getOpenSeaCollectionMetadata", async (address, thunkAPI) => {
  try {
    // Call the API function with parameters
    const data = await fetchDataFromApi(address);
    return {data,address};
  } catch (error) {
    // Handle errors here
    console.log(JSON.stringify(error))
    thunkAPI.dispatch(
      setAlertStatus({
        isOpen: true,
        severity: "error",
        message: "Error retreiving collection details!!",
      })
    );
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

const api =   axios.create({baseURL: apiUrl, headers:{
    Accept:"application/json",
    Authorization: `Bearer token`
}});

const fetchDataFromApi = async (address) => {
  const response = await api.get(
    `collections/opensea-metadata?address=${address}`
  );
  return response.data;
};
