
import { createBrowserRouter } from "react-router-dom";
import { HomePage } from "../pages/home/home";
import React from 'react'
import { CollectionsPage } from "../pages/collections/collections";
import App from '../App';
import { CollectionDetailPage } from "../pages/collections/collection-details";
export const browserRoutes = createBrowserRouter([
    {
        path:'/',
        element:<App />,
        children:[
            {
                path: '/',
                element: (
                    <HomePage/>
                )
            },
            {
                path: "/collections",
                element: (
                    <CollectionsPage />
                )
            },
            {
                path:"/collection/:address",
                element:(
                    <CollectionDetailPage/>
                )
            }
        ]
    }
    
])