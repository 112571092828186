import React, { Fragment, ReactElement } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { RootState } from "../../../store/store";
import { Box, Button, Skeleton, Typography, useTheme } from "@mui/material";
import truncateEthAddress from "truncate-eth-address";
export const HomeBanners = (): ReactElement => {
  const banners = useSelector((state: RootState) => state.app.homeBanners);
  const bannersLoading = useSelector(
    (state: RootState) => state.app.bannerLoading
  );
  const theme = useTheme();
  const isSMScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Fragment>
      {!bannersLoading && (
        <Swiper
          slidesPerView={1}
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          autoplay={{delay: 3}}
        >
          {banners.map((banner) => (
            <SwiperSlide
              className="app-swiper-slide"
              key={`banner___${banner.id}`}
            >
              <img
                className="swiper-banner-img"
                src={banner.ipfsCID}
                alt={banner.collectionName}
              />
              {!isSMScreen && (
                <Box sx={{ position: "absolute", bottom: 30, left: 30 }}>
                  <Typography
                    sx={{ fontWeight: 600 }}
                    color="white"
                    variant="h4"
                  >
                    {banner.collectionName}
                  </Typography>
                  <Typography
                    color="white"
                    variant="body2"
                  >
                    by {truncateEthAddress(banner.collectionAddress)}
                  </Typography>
                  <Button sx={{mt:1}} variant="contained">
                    View Collection
                  </Button>
                </Box>
              )}
              {
                isSMScreen && (
                    <Box sx={{ position: "absolute", bottom: 15, left: 15 }}>
                        <Typography sx={{fontWeight:600, mb:-1}} color="white" variant="h6">
                            {banner.collectionName}
                        </Typography>
                        <Typography color="white" variant="caption">
                            By {truncateEthAddress(banner.collectionAddress)}
                        </Typography>
                        <br/>
                        <Button sx={{mb:1}} variant="contained">
                            View Collection
                        </Button>
                    </Box>
                )
              }
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {bannersLoading && (
        <Skeleton
          animation="wave"
          height="450px"
          variant="rectangular"
          className="home-banner-skeleton"
        />
      )}
    </Fragment>
  );
};
