import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getHomeBanners } from "../thunks/app/get-home-banners";
import {  IHomeBanners } from "../../shared/interfaces/data-interfaces";

interface IAlert{
  isOpen: boolean,
  severity:string,
  message:string,
}
interface IAppSliceState{
  isWalletDetailsOpen: boolean,
  connectionNetwork: number,
  isConnected: boolean,
  account: string |undefined,
  balance: string | undefined,
  homeBanners: IHomeBanners[],
  bannerLoading: boolean,
  alert:IAlert,
  isAuthenticated: boolean
}
const initialState: IAppSliceState = {
  isWalletDetailsOpen: false,
  connectionNetwork: 137,
  isConnected: false,
  account: undefined,
  homeBanners: [],
  balance: undefined,
  bannerLoading: false,
  isAuthenticated: false,
  alert:{
    isOpen: false,
    severity:"",
    message:"",
  }
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    openWalletDetailsPopOver: (state) => {
      state.isWalletDetailsOpen = true;
    },
    closeWalletDetailsPopOver: (state) => {
      state.isWalletDetailsOpen = false;
    },
    setWalletConnectionOn: (state) => {
      state.isConnected = true;
    },
    setWalletConnectionOff: (state) => {
      state.isConnected = false;
    },
    setAddress: (state, action) => {
      state.account = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setAlertStatus:(state, action) => {
      state.alert = action.payload
    },
    setAuthStatus :(state,action) => {
      state.isAuthenticated = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getHomeBanners.pending , (state: IAppSliceState) => {
      state.bannerLoading = true
    });
    builder.addCase(getHomeBanners.fulfilled, (state: IAppSliceState, action: PayloadAction<IHomeBanners[]>) => {
      state.homeBanners = action.payload;
      state.bannerLoading = false
    });
    builder.addCase(getHomeBanners.rejected, (state: IAppSliceState) => {
      state.bannerLoading = false;
    })
  }
});

// Action creators are generated for each case reducer function
export const {
  closeWalletDetailsPopOver,
  openWalletDetailsPopOver,
  setWalletConnectionOff,
  setWalletConnectionOn,
  setAddress,
  setBalance,
  setAuthStatus,
  setAlertStatus
} = appSlice.actions;

export default appSlice.reducer;
