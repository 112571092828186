import React from "react";
import {createRoot} from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { WagmiConfig } from "wagmi";
import {
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { chains, wagmiConfig } from "./connectors/wagmi";
import { RouterProvider } from "react-router-dom";
import {browserRoutes} from "./routes/routes-config"
const container: any = document.getElementById("root");
const root = createRoot(container);

root.render(
  
    <Provider store={store}>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
        // @ts-ignore
          theme={{
            colors: {
              connectButtonBackground: "#daa520",
              accentColor: "#daa520",
              accentColorForeground: "black",
              actionButtonBorder: "rgba(0, 0, 0, 0.04)",
              actionButtonBorderMobile: "rgba(0, 0, 0, 0.06)",
              actionButtonSecondaryBackground: "rgba(0, 0, 0, 0.06)",
              closeButton: "rgba(60, 66, 66, 0.8)",
              closeButtonBackground: "rgba(0, 0, 0, 0.06)",
              connectButtonBackgroundError: "#FF494A",
              connectButtonInnerBackground:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.06))",
              connectButtonText: "#25292E",
              connectButtonTextError: "#FFF",
              connectionIndicator: "#30E000",
              downloadBottomCardBackground:
                "linear-gradient(126deg, rgba(255, 255, 255, 0) 9.49%, rgba(171, 171, 171, 0.04) 71.04%), #FFFFFF",
              downloadTopCardBackground:
                "linear-gradient(126deg, rgba(171, 171, 171, 0.2) 9.49%, rgba(255, 255, 255, 0) 71.04%), #FFFFFF",
              error: "#FF494A",
              generalBorder: "rgba(0, 0, 0, 0.06)",
              generalBorderDim: "rgba(0, 0, 0, 0.03)",
              menuItemBackground: "rgba(60, 66, 66, 0.1)",
              modalBackdrop: "rgba(0, 0, 0, 0.3)",
              modalBackground: "#FFF",
              modalBorder: "transparent",
              modalText: "#25292E",
              modalTextDim: "rgba(60, 66, 66, 0.3)",
              modalTextSecondary: "rgba(60, 66, 66, 0.6)",
              profileAction: "#FFF",
              profileActionHover: "rgba(255, 255, 255, 0.5)",
              profileForeground: "rgba(60, 66, 66, 0.06)",
              selectedOptionBorder: "rgba(60, 66, 66, 0.1)",
              standby: "#FFD641",
            },
            fonts: {
              body: "Rubik",
            },
            radii: {
              actionButton: "9999px",
              connectButton: "12px",
              menuButton: "12px",
              modal: "24px",
              modalMobile: "28px",
            },
          }}
          chains={chains} appInfo={{appName:"Advaya Network (advaya.io)"}}
        >
          
          <RouterProvider router={browserRoutes} />
        </RainbowKitProvider>
      </WagmiConfig>
    </Provider>
);

reportWebVitals(() => {});
