import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig } from "wagmi";
import {
  polygon,
  optimism,
  arbitrum,
  base,
} from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
export const { chains, publicClient } = configureChains(
  [
    polygon,
    optimism,
    arbitrum,
    base],
  [alchemyProvider({ apiKey: "byuVRNZIulrbxipNRt5uIRJqIjFr_pfd" }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "Advaya Network",
  projectId: "451d49aad1e2db98e7967ec6feb408c2",
  chains,
});

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});
