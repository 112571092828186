import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import * as jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface TokenInfo {
  token: string | null;
  decoded: Record<string, unknown> | null; // Use `any` if the token structure is unknown or varies
}

// Custom hook to retrieve and decode token from local storage
function useToken(): TokenInfo {
  const [tokenInfo, setTokenInfo] = useState<TokenInfo>({ token: null, decoded: null });
  const { address, isConnected } = useAccount();
  const isAuthenticated = useSelector((state: RootState) => state.app.isAuthenticated )
  useEffect(() => {
    const fetchAndDecodeToken = (): void => {
      if (isConnected && address ) {
        // Constructing the key using the connected wallet address
        const tokenKey = `token-${address}`;
        // Retrieving the token from local storage
        const storedToken = localStorage.getItem(tokenKey);
        let decodedToken: Record<string, unknown> | null = null;

        // Attempt to decode the token if it exists
        if (storedToken) {
          try {
            decodedToken = jwtDecode<Record<string, unknown>>(storedToken); // Specify the expected type of the decoded token
          } catch (error) {
            console.error("Error decoding token:", error);
            // Handle the error as necessary
          }
        }

        // Update the state with the token and its decoded information
        setTokenInfo({ token: storedToken, decoded: decodedToken });
      } else {
        // If no wallet is connected, set token info to default values
        setTokenInfo({ token: null, decoded: null });
      }
    };

    fetchAndDecodeToken();

    // Re-run this effect if the connection status or address changes
  }, [address, isConnected, isAuthenticated]);

  return tokenInfo;
}

export default useToken;
