import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Divider } from "@mui/material";
import { clsx } from "clsx";
import { MarketPlaceFilter } from "./marketplace";
import { CurrencyFilter } from "./currency";
import { PriceFilter } from "./price";
export const CollectionFilters: React.FC = () => {
  const [expanded, setExpanded] = useState(0);
  const handleAccordionChange =
    (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? index : 0);
    };
  return (
    <Box>
      <Accordion
        expanded={expanded === 1}
        className={clsx('filter-accordion', expanded===1 && 'filter-accordion-active' )}
        elevation={0}
        sx={{ mb: 2 }}
        onChange={handleAccordionChange(1)}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon fontSize="small" />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Marketplace</Typography>
        </AccordionSummary>
        <Divider variant="fullWidth" />
        <AccordionDetails sx={{ borderBottom: "none" }}>
          <Box>
            <MarketPlaceFilter />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 2}
        className={clsx('filter-accordion', expanded===2 && 'filter-accordion-active' )}
        elevation={0}
        sx={{ mb: 2 }}
        onChange={handleAccordionChange(2)}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon fontSize="small" />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Currency</Typography>
        </AccordionSummary>
        <Divider variant="fullWidth" />
        <AccordionDetails>
        <CurrencyFilter />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 3}
        className={clsx('filter-accordion', expanded===3 && 'filter-accordion-active' )}
        elevation={0}
        sx={{ mb: 2 }}
        onChange={handleAccordionChange(3)}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon fontSize="small" />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Price Range</Typography>
        </AccordionSummary>
        <Divider variant="fullWidth" />
        <AccordionDetails>
          <PriceFilter />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
