import React from "react";
import { ApplicationBar } from "./components/app-bar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "../theme/theme";
import { GlobalAlert } from "../shared/components/global-alert";
import { Outlet } from "react-router-dom";

export function Layout(props: any) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalAlert />
        <ApplicationBar />
        <Outlet />
    </ThemeProvider>
  );
}
