import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch } from "../../store";
import { setAlertStatus } from "../../slices/app-slice";
import { AxiosInstance, AxiosResponse } from "axios";
import { INFTResponse } from "../../../shared/interfaces/nft";
interface ThunkInput {
  address: string;
  nextPage?: string;
  api: AxiosInstance;
}
export const getCollectionNFTs = createAsyncThunk<
  INFTResponse,
  any,
  { dispatch: AppDispatch }
>(
  "collections/getNFts",
  async ({ address, api, nextPage }: ThunkInput, thunkAPI) => {
    try {
      if (api) {
        console.log(nextPage)
        const result = await api.get<any, AxiosResponse<INFTResponse>>(
          `/collections/get-nfts?address=${address}&nextPage=${nextPage? nextPage : ''}`
        );
        console.log(result.data);
        return result.data;
      } else {
        return thunkAPI.rejectWithValue(false);
      }
    } catch (error) {
      thunkAPI.dispatch(
        setAlertStatus({
          isOpen: true,
          severity: "error",
          message: "Error retreiving collection details!",
        })
      );
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
