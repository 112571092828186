import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  InputAdornment,
  AutocompleteRenderInputParams,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ICollection } from "../../interfaces/data-interfaces";
import { useEffect } from "react";
import axios from "axios";
import { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface Props {
  initialResults: ICollection[];
}
export const SearchDropdown: React.FC<Props> = (props: Props) => {
  const [value, setValue] = useState<string>("");
  const [timer, setTimer] = useState<any>(undefined);
  const [collections, setCollections] = useState<ICollection[]>([]);

  useEffect(() => {
    // later display top collections
    setCollections(props.initialResults);
  }, [props.initialResults]);

  const handleSearch = async (searchText: string) => {
    setCollections([]);
    if (searchText !== "" || searchText !== undefined) {
      try {
        let searchResult = await axios.get<any, AxiosResponse<ICollection[]>>(
          `${API_URL}collections/search?searchText=${searchText}`
        );
        const data = searchResult.data;
        setCollections(data);
      } catch {
        setCollections(props.initialResults);
      }
    } else {
      setCollections([]);
    }
  };
  const handleChange = (event, newValue) => {
    clearTimeout(timer);
    setValue(newValue);
    setTimer(
      setTimeout(() => {
        handleSearch(newValue);
      }, 1000)
    );
  };
  const filteredOptions = collections.filter((option) =>
    option.name.toLowerCase().includes(value.toLowerCase())
  );

  return (
    <Autocomplete<ICollection, false, false, false>
      freeSolo
      sx={{
        width: {
          lg: "500px",
          md: "300px",
          sm: "100%",
          xs: "100%",
        },
      }}
      inputValue={value}
      onInputChange={handleChange}
      options={filteredOptions}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.name}>
          <ListItemAvatar>
            <Avatar  src={option.imageUrl} />
          </ListItemAvatar>
          <ListItemText primary={option.name} />
        </ListItem>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          fullWidth={true}
          autoComplete="off"
          name="no-autofill"
          placeholder="Search Colections"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
