import { AccountBalanceOutlined } from "@mui/icons-material";
import { ReactComponent as PolygonLogo } from "../../assets/polygon.svg";
import { ReactComponent as ETHLogo } from "../../assets/ethereum.svg";
import { ReactComponent as BNBLogo } from "../../assets/bnb.svg";
import { ReactComponent as BaseLogo } from "../../assets/base.svg";
import { ReactComponent as AvaxLogo } from "../../assets/avax.svg";
import { ReactComponent as ChilizLogo } from "../../assets/chiliz.svg";
import { ReactComponent as GnosisLogo } from "../../assets/gnosis.svg";
import { ReactComponent as FantomLogo } from "../../assets/fantom.svg";
import { ReactComponent as CronosLogo } from "../../assets/cronos.svg";
import { ReactComponent as ArbitrumLogo } from "../../assets/arbitrum.svg";
import { ReactComponent as OptimismLogo } from "../../assets/optimism.svg";
const MATIC = {
  name: "Matic",
  symbol: "MATIC",
  decimals: 18,
};
const XDAI = {
  name: "Gnosis",
  symbol: "XDAI",
  decimals: 18,
};
const CHZ = {
  name: "Chiliz",
  symbol: "CHZ",
  decimals: 18,
};
const ETH = {
  name: "Eth",
  symbol: "ETH",
  decimals: 18,
};
const BSC = {
  name: "Bsc",
  symbol: "BSC",
  decimals: 18,
};
const FTM = {
  name: "Fantom",
  symbol: "FTM",
  decimals: 18,
};
const CRO = {
  name: "Cronos",
  symbol: "CRO",
  decimals: 18,
};
const AVAX = {
  name: "Avalanche",
  symbol: "AVAX",
  decimals: 18,
};

export const CHAINS = {
  137: {
    urls: ["https://polygon-rpc.com"],
    name: "Polygon",
    nativeCurrency: MATIC,
    blockExplorerUrls: ["https://polygonscan.com"],
    logo: PolygonLogo,
  },
  1: {
    urls: ["https://ethereum.publicnode.com"],
    name: "Ethereum",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://etherscan.com"],
    logo: ETHLogo,
  },
  56: {
    urls: ["https://bsc.publicnode.com"],
    name: "Binance Smart Chain",
    nativeCurrency: BSC,
    blockExplorerUrls: ["https://bscscan.com"],
    logo: BNBLogo,
  },
  8453: {
    urls: ["https://base.publicnode.com"],
    name: "Base Network",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://basescan.org"],
    logo: BaseLogo,
  },
  43114: {
    urls: ["https://avalanche-c-chain.publicnode.com"],
    name: "Avalanche-C Chain",
    nativeCurrency: AVAX,
    blockExplorerUrls: ["https://snowtrace.io"],
    logo: AvaxLogo,
  },
  88888: {
    urls: ["https://chiliz.publicnode.com"],
    name: "Chiliz",
    nativeCurrency: CHZ,
    blockExplorerUrls: ["https://scan.chiliz.com/"],
    logo: ChilizLogo,
  },
  100: {
    urls: ["https://rpc.gnosischain.com"],
    name: "Gnosis",
    nativeCurrency: XDAI,
    blockExplorerUrls: ["https://gnosisscan.io"],
    logo: GnosisLogo,
  },
  250: {
    urls: ["https://rpc.ftm.tools/"],
    name: "Fantom Opera",
    nativeCurrency: FTM,
    blockExplorerUrls: ["https://ftmscan.com/"],
    logo: FantomLogo,
  },
  10: {
    urls: ["https://optimism.publicnode.com"],
    name: "Optimism",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://optimistic.etherscan.io/"],
    logo: OptimismLogo,
  },
  25: {
    urls: ["https://cronos-evm.publicnode.com"],
    name: "Cronos",
    nativeCurrency: CRO,
    blockExplorerUrls: ["https://cronoscan.io"],
    logo: CronosLogo,
  },
  42161: {
    urls: ["https://arb1.arbitrum.io/rpc"],
    name: "Arbitrum One",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://arbiscan.io"],
    logo: ArbitrumLogo,
  },
};

export function getAddChainParameters(chainId) {
  const chainInformation = CHAINS[chainId];
  if (chainInformation) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    };
  } else {
    return chainId;
  }
}

export function getAddCoinbaseChainParameters(chainId) {
  const chainInformation = CHAINS[chainId];
  if (chainInformation) {
    return {
      chainId:`0x${chainId.toString(16)}`,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    };
  } else {
    return chainId;
  }
}

export const getNetworkLogo = (chainId) => {
  const chain = CHAINS[chainId];
  if (chain) {
    return chain.logo;
  } else {
    return AccountBalanceOutlined;
  }
};

export const getPriceSymbol = (chainId) => {
  switch (chainId) {
    case 137:
      return "MATIC";
    case 56:
      return "BNB";
    case 25:
      return "CRO";
    case 1:
      return "ETH";
    case 8453:
      return "ETH";
    case 43114:
      return "AVAX";
    case 88888:
      return "CHZ";
    case 100:
      return "DAI";
    case 250:
      return "FTM";
    case 10:
      return "ETH";
    case 42161:
      return "ETH";
    default:
      return "ETH";
  }
};

export const getChainCurrency = (chainId) => {
  switch (chainId) {
    case 137:
      return "MATIC";

    case 56:
      return "BNB";
    case 25:
      return "CRO";
    case 1:
      return "ETH";
    case 8453:
      return "ETH";
    case 43114:
      return "AVAX";
    case 88888:
      return "CHZ";
    case 100:
      return "XDAI";
    case 250:
      return "FTM";
    case 10:
      return "ETH";
    case 42161:
      return "ETH";
    default:
      return "ETH";
  }
};
export const isSupportedNetwork = (chainId) => {
  switch (chainId) {
    case 137:
      return true;

    case 56:
      return true;
    case 25:
      return true;
    case 1:
      return false;
    case 8453:
      return true;
    case 43114:
      return true;
    case 88888:
      return true;
    case 100:
      return true;
    case 250:
      return true;
    case 10:
      return true;
    case 42161:
      return true;
    default:
      return false;
  }
};
export const URLS= Object.keys(CHAINS).reduce(
  (accumulator, chainId) => {
    const validURLs = CHAINS[Number(chainId)].urls

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs
    }

    return accumulator
  },
  {}
)