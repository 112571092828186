import { Grid } from '@mui/material'
import React from 'react'
import { CollectionFilters } from '../../../shared/filters/collection-filters'
import { ICollection } from '../../../shared/interfaces/data-interfaces'
import { FilterSkeleton } from '../../../shared/skeletons/filter-skeletons'
import {CollectionNFTs} from "./collection-nfts";
interface Props{
    collection?: ICollection
}
export const CollectionTab : React.FC<Props> = ({collection}) => {
    return (
        <Grid container spacing={3} sx={{pt:3, pb:50}}>
            <Grid item lg={3} md={3} sm={3}>
                {
                    collection ? <CollectionFilters /> : <FilterSkeleton />
                }
            </Grid>
            <Grid item lg={9} md={9} sm={9}>
                <CollectionNFTs collection={collection} />
            </Grid>
        </Grid>
    )
}