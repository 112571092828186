import {
  AppBar,
  Button,
  Container,
  CssBaseline,
  IconButton,
  Skeleton,
  SvgIconTypeMap,
  Toolbar,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { AccountBalanceWalletOutlined, SwapHoriz } from "@mui/icons-material";
import { useAccount, useBalance, useNetwork } from "wagmi";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import WalletDetailPopOver from "../../shared/components/wallet/wallet-detail";
import { openWalletDetailsPopOver } from "../../store/slices/app-slice";
import { getNetworkLogo, isSupportedNetwork } from "../../shared/data/chains";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useAppDispatch } from "../../store/store";
import {  useNavigate } from "react-router-dom";

export function ApplicationBar(props) {
  const dispatch = useAppDispatch();
  const [anchor, setAnchor] = useState(null);
  const [isNetSupported, setIsNetSupported] = useState(true);
  const { openConnectModal } = useConnectModal();
  const account = useAccount();
  const [NetworkLogo, setLogo] = useState<OverridableComponent<SvgIconTypeMap>>(AccountBalanceWalletOutlined);
  const handleOpenWalletDetails = (event) => {
    dispatch(openWalletDetailsPopOver());
    setAnchor(event.target);
  };
  const {openChainModal} = useChainModal();

  const { chain } = useNetwork();
const navigate = useNavigate();
  useEffect(() => {
    
    if (chain && chain.id) {
      setIsNetSupported(isSupportedNetwork(chain.id));
      setLogo(getNetworkLogo(chain.id));
    }
  }, [chain]);

  const { data: balance } = useBalance({
    address: account.address,
    watch: true,
  });
  return (
    <Fragment>
      <CssBaseline />
      <AppBar
        sx={{
          backgroundColor: "rgb(52, 67, 104)",
          
        }}
        position="static"
      >
        <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo onClick={() => navigate("/")} className="logo-main" />
          <span className="spacer"></span>
          {/* <ConnectButton /> */}
          <Button className="nav-button" onClick={() => navigate("/collections")} sx={{mr:4, textTransform: "capitalize", fontSize:"1.1rem", fontWeight:600}} variant="text" >
            Collections
          </Button>
          <Button className="nav-button" sx={{mr:4, textTransform: "capitalize", fontSize:"1.1rem", fontWeight:600}} variant="text" >
            Market
          </Button>
          <Button className="nav-button" sx={{mr:4, textTransform: "capitalize", fontSize:"1.1rem", fontWeight:600}} variant="text" >
            Drops
          </Button>
          {account.isDisconnected && (
            <Button
              onClick={openConnectModal}
              startIcon={<AccountBalanceWalletOutlined />}
              variant="contained"
            >
              Connnect Wallet
            </Button>
          )}
          {account.isConnected && isNetSupported && (
            <Button
              startIcon={<NetworkLogo className="chain-logo" />}
              onClick={handleOpenWalletDetails}
              variant="contained"
            >
              {balance ? (
                `${Number(balance.formatted).toFixed(4)} ${balance.symbol}`
              ) : (
                <Skeleton
                  variant="text"
                  width="100px"
                  sx={{ fontSize: "1rem" }}
                />
              )}
            </Button>
          )}
          {account.isConnected && isNetSupported && (
            <IconButton>
              <Jazzicon
                diameter={40}
                seed={jsNumberForAddress(account.address as string)}
              />
            </IconButton>
          )}
          {!isNetSupported && (
            <Button onClick={openChainModal} startIcon={<SwapHoriz />} variant="contained" color="error">
              Switch Network
            </Button>
          )}
        </Toolbar>
        </Container>
      </AppBar>
      <WalletDetailPopOver anchorEl={anchor} />
    </Fragment>
  );
}
