import {  Box, Skeleton } from "@mui/material";
import React from "react";

export const FilterSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" height="60px" />
      <Skeleton sx={{mt:2}} variant="rectangular" height="60px" />
      <Skeleton sx={{mt:2}} variant="rectangular" height="60px" />
    </Box>
  );
};
