
import useToken from "./use-token";
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL
export function useApi(){
    const { token } = useToken();
 function createAPI(){
    const axiosInstance =  axios.create({baseURL:apiUrl, headers:{
        Authorization: `Bearer ${token}`
    }});
    return axiosInstance;
}
    return createAPI()
}