import { createTheme } from "@mui/material";

export const theme = createTheme({
    typography:{
        fontFamily: [
            'Rubik',
            'sans-serif',
          ].join(','),
    },
    palette:{
        primary:{
            main:"#daa520"
        },
        secondary:{
            main: "#344368"
        }
    }
})