/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Popover,
  Skeleton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount, useBalance, useDisconnect, useNetwork } from "wagmi";

import { ReactComponent as MetamaskLogo } from "../../../assets/metamask.svg";

import { ReactComponent as WalletConnectLogo } from "../../../assets/wallet-connect.svg";
import { ReactComponent as CoinbaseLogo } from "../../../assets/coinbase.svg";
import truncateEthAddress from "truncate-eth-address";
import copy from "copy-to-clipboard";
import {
  closeWalletDetailsPopOver,
  setAlertStatus,
} from "../../../store/slices/app-slice";
import {
  ArrowForward,
  ContentCopy,
  PowerSettingsNew,
} from "@mui/icons-material";
import { getChainCurrency } from "../../data/chains";
import { useChainModal } from "@rainbow-me/rainbowkit";
import { RootState } from "../../../store/store";
import React from 'react'

export default function WalletDetailPopOver(props) {
  const isOpen = useSelector((state:RootState) => state.app.isWalletDetailsOpen);
  const { isConnected, address, connector } = useAccount();
  const [Logo, setLogo] = useState<any>(MetamaskLogo);
  const [money, setMoney] = useState<string | undefined>("");
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  const { data:balance, } = useBalance({
    address: address,
    watch: true,
  });
  const {openChainModal} = useChainModal();

  useEffect(() => {
    if(balance && balance.formatted){
      getPrice();
    }
  }, [balance])
  const getPrice = async () => {
    setMoney(undefined)
    const currency = getChainCurrency(chain!.id);
    if (currency !== "CRO") {
      const result = await fetch(
        `https://api.binance.com/api/v3/ticker/price?symbol=${getChainCurrency(
          chain!.id
        )}USDT`
      );
      const priceData = await result.json();
      setMoney(Number(Number(balance!.formatted) * Number(priceData.price)).toFixed(4));
    } else if (currency === "CRO") {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}utils/cro-price`
      );
      const priceData = await result.json();
      setMoney(Number(Number(balance!.formatted) * Number(priceData.price)).toFixed(4));
    } else {
      setMoney("0.0000");
    }
  };

  useEffect(() => {
    if (isConnected) {
      if (connector) {
        if (connector.name.toLowerCase() === "metamask") {
          setLogo(MetamaskLogo);
        }
        if (connector.name.toLowerCase() === "walletconnect") {
          setLogo(WalletConnectLogo);
        }
        if (connector.name.toLowerCase() === "coinbase wallet") {
          setLogo(CoinbaseLogo);
        }
      }
    }
  }, [connector, isConnected]);
  const handleAddressCopy = () => {
    copy(address!);
    dispatch(
      setAlertStatus({
        isOpen: true,
        severity: "success",
        message: "Wallet Address copied!",
      })
    );
  };
  const handleClose = () => {
    dispatch(closeWalletDetailsPopOver());
  };

  const handleLogout = () => {
    dispatch(closeWalletDetailsPopOver());

    disconnect();
    localStorage.clear();
  };
  return (
    <Popover
      id="wallet-popover"
      open={isOpen}
      anchorEl={props.anchorEl}
      onClose={handleClose}
      sx={{ mt: 1, width: "100%" }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {isConnected && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={"100%"}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            width={"100%"}
          >
            <Box width={"100%"}>
              <ListItem>
                <Logo className="wallet-popover-logo" />
                <ListItemText>
                  <Typography>{truncateEthAddress(address as string)}</Typography>
                </ListItemText>
                <IconButton onClick={handleAddressCopy}>
                  <ContentCopy color="primary" />
                </IconButton>
              </ListItem>
            </Box>
            <Box>
              <ListItem>
                <IconButton onClick={handleLogout}>
                  <PowerSettingsNew color="primary" />
                </IconButton>
              </ListItem>
            </Box>
          </Box>
          <Box>
            {money ? (
              <Typography variant="h3">{`$${money} `}</Typography>
            ) : (
              <Skeleton
                width={"180px"}
                variant="text"
                sx={{ fontSize: "3rem" }}
              />
            )}
          </Box>
          <Divider />
          <Box sx={{ pb: 1 }}>
            <Button
              sx={{ textTransform: "capitalize" }}
              endIcon={<ArrowForward />}
              onClick={openChainModal}
            >
              Switch Network
            </Button>
          </Box>
        </Box>
      )}
    </Popover>
  );
}

// function getWalletLogoByAgent(agent) {
//   switch (agent) {
//     case "METAMASK":
//       return MetamaskLogo;
//     default:
//       return MetamaskLogo;
//   }
// }
