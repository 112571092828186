/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Box,
  useTheme,
  useMediaQuery,
  Skeleton,
  Tabs,
  Tab,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import {CustomTabPanel} from "../../shared/components/tabs/custom-tab-pannel";
import { getSelectedCollection } from "../../store/thunks/collections/get-selected-collection";
import { CollectionMeta } from "./components/collection-meta";
import { CollectionTab } from "./components/collection-tab";
import { destroyCollections } from "../../store/slices/collections-slice";

export const CollectionDetailPage: React.FC = () => {
  const { address } = useParams();
  const theme = useTheme();
  const belowSM = useMediaQuery(theme.breakpoints.down("sm"));
  const belowMD = useMediaQuery(theme.breakpoints.down("md"));
  const belowLG = useMediaQuery(theme.breakpoints.down("lg"));
  const [bannerHeight, setBannerHeight] = useState(500);
  const [bannerWidth, setBannerWidth] = useState(0);
  const [tabIndex, setTabIndex] = React.useState(0);
  const elementRef = useRef<HTMLElement>(null);
  const dispatch = useAppDispatch();

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    return () => {
      dispatch(destroyCollections())
    }
  },[])
  
  useEffect(() => {
    const currentWidth1 = elementRef.current?.getBoundingClientRect().width;
    if (currentWidth1) setBannerWidth(currentWidth1);
    console.log("width", currentWidth1);
    const updateWidth = () => {
      const currentWidth = elementRef.current?.getBoundingClientRect().width;
      console.log("width1", currentWidth);
      if (currentWidth) setBannerWidth(currentWidth);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [belowLG, belowMD, belowSM]);

  const collection = useSelector(
    (state: RootState) => state.collections.selectedCollection
  );

  useEffect(() => {
    if (address) {
      dispatch(getSelectedCollection(address));
    }
  }, [address]);
  useEffect(() => {
    setBannerHeight(calculateBannerHeight());
  }, [belowLG, belowMD, belowSM]);
  const calculateBannerHeight = () => {
    if (!belowLG) {
      return 450;
    } else if (belowMD && !belowSM) {
      return 400;
    } else if (belowLG && belowMD && belowSM) {
      return 300;
    } else {
      return 500;
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  return (
    <>
      <Container maxWidth="xl">
        <Box
          ref={elementRef}
          width="100%"
          height={`${bannerHeight}px`}
          sx={{
            mt: 4,
            borderRadius: "1rem",
            position: "relative",
          }}
        >
          <Box
            sx={{
              top: bannerHeight - 120 / 2,
              left: belowLG ? bannerWidth / 2 - 120 / 2 : 30,
            }}
            className="collection-avatar"
          >
            {collection && (
              <img
                style={{ width: "100%", height: "100%", backgroundColor:theme.palette.grey[100] }}
                src={
                  collection.bannerUrl
                    ? collection.bannerUrl
                    : collection.imageUrl
                }
                alt="banner"
              />
            )}
            {!collection && (
              <Skeleton
                sx={{ width: "100%", height: "100%" }}
                variant="circular"
              />
            )}
          </Box>

          {collection && (
            <img
              className="collection-banner"
              style={{backgroundColor:theme.palette.grey[100]}}
              src={
                collection.bannerUrl
                  ? collection.bannerUrl
                  : collection.imageUrl
              }
              alt="banner"
            />
          )}
          {!collection && (
            <Skeleton
              sx={{ width: "100%", height: "100%", borderRadius: "1rem" }}
              variant="rectangular"
            />
          )}
        </Box>
      </Container>
      <Container maxWidth={"xl"}>
        <CollectionMeta collection={collection} />
      </Container>
      <Container maxWidth="xl">
      <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs textColor="secondary" value={tabIndex} onChange={handleTabChange} aria-label="collection-tabs">
          <Tab color="primary" label="Collection" {...a11yProps(0)} />
          <Tab label="Activity" {...a11yProps(1)} />
          <Tab label="Discussions" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabIndex} index={0}>
        <CollectionTab collection={collection} />
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={1}>
        Item Two
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={2}>
        Item Three
      </CustomTabPanel>
    </Box>
      </Container>
    </>
  );
};
