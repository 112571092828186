/* eslint-disable react-hooks/exhaustive-deps */
import "./App.scss";
import { Auth } from "./auth/auth";
import { Layout } from "./layout/layout";
import React, { ReactElement, useEffect } from "react";
import { getCollectionsByPage } from "./store/thunks/collections/get-collection";
import { useAppDispatch } from "./store/store";
import { getHomeBanners } from "./store/thunks/app/get-home-banners";
import { getRandomString } from "./shared/utils/array-utils";
import { bgColors } from "./shared/data/colors";

function App(): ReactElement {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCollectionsByPage(1));
    dispatch(getHomeBanners());
    // dispatch(
    //   getTopMintingCollections({
    //     hours: 24,
    //     pageNumber: 1,
    //     pageSize: 20,
    //   })
    // );
  }, []);

  return (
    <div style={{backgroundColor: getRandomString(bgColors)}} className="App">
      <Auth />
      <Layout>
        <h1>I am ashivam</h1>
      </Layout>
    </div>
  );
}

export default App;
