import { Box, Container, Grid, Skeleton } from "@mui/material";
import React from "react";

export const NFTSkeletons = () => {
  return (
    <Grid container spacing={2} rowSpacing={2} >
      {Array.from({ length: 20 }, (_, i) => i + 1).map((number) => {
        return (
          <Grid key={`item__${number}`} item lg={3} md={4} sm={12}>
            <Box
              key={number}
              height={"350px"}
              sx={{ borderRadius: "0.8rem" }}
              className="custom-paper"
            >
              <Skeleton
                variant="rectangular"
                sx={{
                  borderTopLeftRadius: "0.8rem",
                  borderTopRightRadius: "0.8rem",
                  height: "250px",
                }}
              />
              <Container>
                <Skeleton sx={{ mt: 1 }} variant="text" />
              </Container>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
