import React, { ReactElement, useEffect } from "react";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";

import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
//@ts-ignore
import { CollectionCard } from "./components/collection-card";
import { SkeletonCard } from "../../shared/components/skeleton-card";
import { getCollectionsByPage } from "../../store/thunks/collections/get-collection";
import { bgColors } from "../../shared/data/colors";
import { SearchDropdown } from "../../shared/components/search-box/search-dropdown";
import { useTheme } from "@mui/material";
import { useState } from "react";

export const CollectionsPage = (): ReactElement => {
  const collections = useSelector(
    (state: RootState) => state.collections.collections
  );
  const dispatch = useAppDispatch();
  const collectionLoading = useSelector(
    (state: RootState) => state.collections.loading
  );
  const [headerBreakPoint, setHeaderBreakpoint] = useState(false);
  const page = useSelector((state: RootState) => state.collections.page);
  const hasMore = useSelector((state: RootState) => state.collections.hasMore);
  const theme = useTheme();
  const isSMScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMDScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isXSScreen = useMediaQuery(theme.breakpoints.down("xs"));
  useEffect(() => {
    setHeaderBreakpoint(isSMScreen || isXSScreen || isMDScreen);
  }, [isSMScreen, isXSScreen, isMDScreen]);

  return (
    <Container maxWidth={"xl"}>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexFlow: headerBreakPoint ? "row-reverse" : "row",
          flexDirection: headerBreakPoint ? "column" : "row",
          alignItems: headerBreakPoint ? "start" : "center",
        }}
      >
        <Box
          sx={{ mb: headerBreakPoint ? 2 : 0 }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            All Collections
          </Typography>
        </Box>
        <span className="spacer" />
        <SearchDropdown initialResults={collections} />
      </Box>
      <InfiniteScroll
        style={{ width: "100%" }}
        loader={<h1>Loading...</h1>}
        dataLength={collections.length}
        hasMore={hasMore}
        next={() => dispatch(getCollectionsByPage(page + 1))}
      >
        <Grid sx={{ mt: 2 }} container spacing={3} rowSpacing={3}>
          {collections.map((collection, i) => {
            return (
              <Grid
                key={`collection___${i}`}
                width="100%"
                item
                xl={3}
                lg={3}
                md={4}
                sm={6}
                xs={12}
              >
                <CollectionCard bgColor={bgColors[i]} collection={collection} />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>

      {collectionLoading && (
        <Grid
          container
          spacing={3}
          rowSpacing={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {[...Array(8)].map((e, i) => {
            return (
              <Grid
                sx={{ mt: 2 }}
                item
                lg={3}
                sm={12}
                key={`${i}__skeletonIndex`}
              >
                <SkeletonCard />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Container>
  );
};
