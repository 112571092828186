/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ICollection } from "../../../shared/interfaces/data-interfaces";
import { Box, Container, Grid, Skeleton } from "@mui/material";
import { RootState, useAppDispatch } from "../../../store/store";
import { getCollectionNFTs } from "../../../store/thunks/collections/getNFTs";
import { useApi } from "../../../shared/hooks/use-api";
import { useSelector } from "react-redux";
import { INFT } from "../../../shared/interfaces/nft";
import { NFTCard } from "./nft-card";
import { NFTSkeletons } from "../../../shared/skeletons/nfts-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
interface Props {
  collection?: ICollection;
}
export const CollectionNFTs: React.FC<Props> = ({ collection }) => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const hasMore = useSelector((state: RootState) =>  state.collections.selectedCollectionNfts.hasMore);
  const nextPointer = useSelector((state: RootState) =>  state.collections.selectedCollectionNfts.next);
  const data = useSelector(
    (state: RootState) => state.collections.selectedCollectionNfts.data
  );
  useEffect(() => {
    if (collection && collection.address) {
      dispatch(getCollectionNFTs({ address: collection.address, api: api }));
    }
  }, [collection]);
  return (
    <Box width={"100%"}>
      <InfiniteScroll
      dataLength={data.length}
        hasMore={hasMore}
        loader={<NFTSkeletons />}
        next={() =>
          dispatch(
            getCollectionNFTs({
              address: collection?.address,
              api: api,
              nextPage: nextPointer,
            })
          )
        }
      >
        {data && (
          <Grid sx={{ width: "100%" }} container spacing={2} rowSpacing={2}>
            {data.map((nft: INFT, index: number) => {
              return (
                <Grid key={`item__${index}`} item lg={3} md={4} sm={12}>
                  <NFTCard nft={nft} />
                </Grid>
              );
            })}
          </Grid>
        )}
      </InfiniteScroll>
      {data.length === 0 && <NFTSkeletons />}
    </Box>
  );
};
