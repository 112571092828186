import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { setAlertStatus } from "../../store/slices/app-slice";
import { useDispatch, useSelector } from "react-redux";

const Alert: any = React.forwardRef(function Alert(props, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export function GlobalAlert() {
  const alert = useSelector((state:any) => state.app.alert);
  const dispatch = useDispatch();
  const handleClose = (event:any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setAlertStatus({isOpen: false, severity:'success', message:''}));
  };
  return (
    <Snackbar open={alert.isOpen} autoHideDuration={3000} onClose={handleClose}>
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={alert.severity}
        sx={{ width: "100%" }}
      >
        {alert.message}
      </Alert>
    </Snackbar>
  );
}
