import { Box, Card, CardContent, Skeleton } from "@mui/material";
import React, { ReactElement } from "react";

export const SkeletonCard = (): ReactElement => {
  return (
    <Card>
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ width: "100%", height: "300px" }}
      />
      <CardContent>
        <Box display="flex" width="100%" flexDirection="row">
          <Skeleton
            variant="text"
            sx={{ fontSize: 28, width: "100%", height: "30px" }}
          />

          <span style={{ width: "40px" }} className="spacer" />
          <Skeleton variant="circular" sx={{ width: "40px", height: "40px" }} />
        </Box>
      </CardContent>
    </Card>
  );
};
