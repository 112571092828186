import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../../store";
import { setAlertStatus } from "../../slices/app-slice";
import { ICollection } from "../../../shared/interfaces/data-interfaces";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
export const getSelectedCollection = createAsyncThunk<
  ICollection,
  string,
  { dispatch: AppDispatch}
>("collections/getSelectedCollection", async (address, thunkAPI) => {
  try {
    const state: RootState|any = thunkAPI.getState();
    if(state.collections.collections.length > 0){
        
        return state.collections.collections.find((x) => x.address === address)
    }else{
        return fetchDataFromApi(address)
    }
  } catch (error) {
    // Handle errors here
    thunkAPI.dispatch(
      setAlertStatus({
        isOpen: true,
        severity: "error",
        message: "Error retreiving collection details!",
      })
    );
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

const api =   axios.create({baseURL: apiUrl, headers:{
    Accept:"application/json",
    Authorization: `Bearer token`
}});

const fetchDataFromApi = async (address) : Promise<ICollection> => {
  let response = await api.get(
    `collections/detail?address=${address}`
  );
  let result =  response.data;
  return {...result.data, openSeaMetadata: result.openSeaMetadata}
};

