/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from "react";
import { ICollection } from "../../../shared/interfaces/data-interfaces";
import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { noImgCid } from "../../../store/thunks/collections/get-collection";
import { useRef } from "react";
import { useAppDispatch } from "../../../store/store";
import { getOpenSeaCollectionMetadata } from "../../../store/thunks/collections/get-total-supply";
interface Props {
  collection: ICollection;
  bgColor: string;
}
export const CollectionCard = (props: Props): ReactElement => {
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setLoading] = useState(true);
  const elementRef = useRef<HTMLAnchorElement>(null);
  const [width, setWidth] = useState(0);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const currentWidth1 = elementRef.current?.getBoundingClientRect().width;
    if (currentWidth1) setWidth(currentWidth1);
    const updateWidth = () => {
      const currentWidth = elementRef.current?.getBoundingClientRect().width;
      if (currentWidth) setWidth(currentWidth);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [isLoading]);

  useEffect(() => {
    if (props && props.collection && props.collection.address) {
      dispatch(getOpenSeaCollectionMetadata(props.collection.address));
    }
  }, [props.collection.address]);

  useEffect(() => {
    setImageUrl(props.collection.imageUrl);
  }, [props.collection.imageUrl]);

  const handleImageLoadingError = (err) => {
    setLoading(true);
    setImageUrl(`https://ipfs.io/ipfs/${noImgCid}`);
  };
  return (
    <Card sx={{ width: "100%" }} elevation={5}>
      <Link
        ref={elementRef}
        style={{ height: "300px", position: "relative" }}
        to={`/collection/${props.collection.address}`}
      >
        <Box
          sx={{
            position: "absolute",
            padding: "5px",
            backgroundColor: "white",
            borderRadius: "50px",
            height: "100px",
            top: 250,
            left: width / 2 - 50,
          }}
          width={"100px"}
        >
          <img
            alt="collection-preview"
            src={`${
              props.collection.avatarUrl ? props.collection.avatarUrl : imageUrl
            }`}
            height={"100%"}
            width="100%"
            style={{
              display: isLoading ? "none" : "block",
              borderRadius: "50px",
            }}
            onError={handleImageLoadingError}
            onLoad={() => {
              setLoading(false);
            }}
          />
        </Box>
        <Box width="100%" height={"300px"}>
          {isLoading && (
            <Skeleton
              variant="rectangular"
              animation="wave"
              sx={{ width: "100%", height: "300px" }}
            />
          )}
          <img
            className="collection-card-img"
            alt="collection-preview"
            src={`${imageUrl}`}
            height={"300px"}
            width="100%"
            style={{ display: isLoading ? "none" : "block" }}
            onError={handleImageLoadingError}
            onLoad={() => {
              setLoading(false);
            }}
          />
        </Box>
      </Link>
      <CardContent sx={{ backgroundColor: props.bgColor }}>
        <Box
          display="flex"
          width="100%"
          sx={{ pt: 5, backgroundColor: props.bgColor }}
          flexDirection="column"
          justifyContent={"center"}
          alignItems="center"
        >
          <Link
            style={{ width: "100%" }}
            className="router-text-link"
            to={`/collection/${props.collection.address}`}
          >
            <Typography
              className="truncate"
              sx={{
                fontWeight: 700,
                fontSize: "1.5rem",
                lineHeight: "2rem",
                textAlign: "center",
              }}
            >
              {props.collection.name}
            </Typography>
          </Link>
        </Box>
        <Box
          width={"100%"}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Typography
            sx={{ fontWeight: 400 }}
            color={"GrayText"}
            variant="body2"
          >
            Floor
          </Typography>
          <span className="spacer" />
          <Typography
            sx={{ fontWeight: 400 }}
            color={"GrayText"}
            variant="body2"
          >
            Supply
          </Typography>
        </Box>
        <Box
          width={"100%"}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Typography
            sx={{ fontWeight: 400 }}
            color={"GrayText"}
            variant="body2"
          >
            0.00ETH
          </Typography>
          <span className="spacer" />
          <Typography
            sx={{ fontWeight: 400 }}
            color={"GrayText"}
            variant="body2"
          >
            {props.collection.totalSupply}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
