export function sortArrayOfObjects<T>(array: T[], propertyName: keyof T, order: 'asc' | 'desc'): T[] {
    const newArray = array.slice();
    return newArray.sort((a, b) => {
      if (order === 'asc') {
        return a[propertyName] > b[propertyName] ? 1 : -1;
      } else if (order === 'desc') {
        return a[propertyName] < b[propertyName] ? 1 : -1;
      } else {
        throw new Error('Invalid order parameter. Please provide "asc" or "desc".');
      }
    });
  }

export  function getRandomString(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }