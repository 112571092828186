import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";

export const MarketPlaceFilter = () => {
  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox defaultChecked />} label="Advaya" />
      <FormControlLabel control={<Checkbox />} label="Opensea" />
    </FormGroup>
  );
};
