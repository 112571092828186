/* eslint-disable react-hooks/exhaustive-deps */
import { CssBaseline } from "@mui/material";
import axios from "axios";
import React, { ReactElement, useEffect } from "react";
import { useAccount, useNetwork, useSignMessage } from "wagmi";
import { useAppDispatch } from "../store/store";
import { setAuthStatus } from "../store/slices/app-slice";

const apiUrl = process.env.REACT_APP_API_URL;
export const Auth = (): ReactElement => {
  const { isConnected, address } = useAccount();
  const { chain } = useNetwork();
  const { signMessageAsync } = useSignMessage();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isConnected && address!.length > 0) {
      const token = localStorage.getItem(`token-${address}`);
      if (token) {
        //authenticate if fails initiate resigning
        requestAuth(token);
    
    } else {
        //initiate user sign
        requestMessage();
      }
    }
  }, [isConnected, address]);
  const requestAuth = async (token: string) => {
    try {
      const authResult = await axios.post(
        `${apiUrl}auth/authenticate`,
        { jwt: token },
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (authResult.data.address.toLowerCase() === address!.toLowerCase()) {
        console.log("authentication successfull!");
      } else {
        requestMessage();
      }
    } catch (e:any) {
      console.log("Failed in authentication!", e.message);
      dispatch(setAuthStatus(false));
      requestMessage();
    }
  };
  const requestMessage = async () => {
    try {
      // request signing message
      const requestMessageResult = await axios.post(
        `${apiUrl}auth/request-message`,
        { address, chain: chain!.id },
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      // request signature
      const signature = await signMessageAsync({
        message: requestMessageResult.data.message,
      });
      //request verify
      const verifyResult = await axios.post(
        `${apiUrl}auth/verify`,
        {
          message: requestMessageResult.data.message,
          signature,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      localStorage.setItem(`token-${address}`, verifyResult.data.token);
      localStorage.setItem(
        `user-${address}`,
        JSON.stringify(verifyResult.data.user)
      );
      dispatch(setAuthStatus(true));
    } catch (e:any) {
      console.log("Failed initiating the siging process.", e.message);
      dispatch(setAuthStatus(false));
    }
  };
  return <CssBaseline />;
};
