import {  TopMintingCollections } from "./../../../shared/interfaces/data-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { setAlertStatus } from "../../slices/app-slice";
import { AppDispatch } from "../../store";
const apiUrl = process.env.REACT_APP_API_URL;
export const noImgCid = "bafkreihyaeahugm4esjtqrq4227kariwvz3l5w6kyj6dxejjtybmbb2c74";
interface Params{
    pageSize:number,
    pageNumber:number,
    hours:number
}
export const getTopMintingCollections = createAsyncThunk<TopMintingCollections[], Params, { dispatch: AppDispatch }>("collections/topMintingCollections", async (params, thunkAPI) => {
  try {
    // Call the API function with parameters
    const data = await fetchDataFromApi(params);
    return data;
    
  } catch (error) {
    console.log(error)
    // Handle errors here
    thunkAPI.dispatch(
      setAlertStatus({
        isOpen: true,
        severity: "error",
        message: "Error retreiving NFT collections!",
      })
    );
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

const fetchDataFromApi = async (params: Params) => {
  const response = await axios.get<any, AxiosResponse<TopMintingCollections[]>, any>(
    `${apiUrl}collections/top-minting?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}&hours=${params.hours}`
  );
  return response.data.map((x) => {
    const obj: TopMintingCollections = { ...x, collectionImage: processUrl(x.collectionImage) };
    return  obj;
  });
};

const processUrl = (url: string) => {
  let newUrl = url;
  if (url.startsWith("ipfs://")) {
    newUrl = url.replace("ipfs://", "https://ipfs.io/ipfs");
  } else if (url.includes("/ipfs/")) {
    newUrl = `https://ipfs.io/ipfs/${url.split("/ipfs/")[1]}`;
  } else if (url.startsWith("data:")) {
    newUrl = url;
  } else if (url === "NO_IMAGE") {
    newUrl = `https://ipfs.io/ipfs/${noImgCid}`;
  }
  return newUrl;
};
